import { useEffect, useState } from 'react';
import { Card, Flex, Space, Spin, Tag } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';
import axios from 'axios';
import Detail from './Detail';

const App = () => {
    const [pageNumber, setPageNumber] = useState(1);
    const [tagId, setTagId] = useState(41);
    const [loading, setLoading] = useState(false); // 加载状态
    const [data, setData] = useState<any[]>([]);
    const [hasMore, setHasMore] = useState(true);
    const [dialogVisible, setDialogVisible] = useState(false);
    const [selectedId, setSelectedId] = useState(null);
    const [selectedTitle, setSelectedTitle] = useState(null);

    const mergeData = (data: any[], newData: any[]) => {
      const d = [...data, ...newData];
      setData(d);
    }

    useEffect(() => {
      const fetchData = () => {
        console.log(tagId, pageNumber);
        setLoading(true);
        // 在组件加载时发起数据请求
        axios.get(`https://api.moegoat.com/api/user/library/tag/id?tag_id=${tagId}&sort=choicest&page=${pageNumber}`)
          .then((res) => {
            const newData = res.data.data;
            if (newData.length === 0) {
              setHasMore(false);
            } else {
              mergeData(data, newData);
            }
          })
          .catch((e) => {
            console.log(e)
          })
          .finally(() => {
            setLoading(false)
          })
      }

      fetchData();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [pageNumber, tagId]);

    useEffect(() => {
      const handleScroll = () => {
        const scrollTop = document.documentElement.scrollTop || document.body.scrollTop;
        const windowHeight = window.innerHeight;
        const scrollHeight = document.documentElement.scrollHeight;

        if (scrollTop + windowHeight >= scrollHeight - 100 && !loading && hasMore) {
          if (!loading && hasMore) {
            setLoading(true)
            const page = pageNumber + 1
            setPageNumber(page);
          }
        }
      };

      window.addEventListener('scroll', handleScroll);
      return () => {
        window.removeEventListener('scroll', handleScroll);
      };
    }, [hasMore, loading, pageNumber]);

    const handleOpenDialog = (id: any, title: any) => {
      setSelectedId(id);
      setSelectedTitle(title);
      setDialogVisible(true);
    };

    const handleCloseDialog = () => {
      setDialogVisible(false);
    };

    const changeTag = (tag: any) => {
      if (tag) {
        setData([]);
        setTagId(tag);
        setPageNumber(1);
      }
    }

    // 响应式展示图片
    return (
      <div className="App">
        <Flex wrap="wrap" gap="small">
          {Array.from(data, (item: any, i) => (
            <Card key={item['id'] + i.toString()}
              hoverable
              // style={{ width: 240 }}
              cover={<img alt={item['loi_title']} onClick={() => handleOpenDialog(item['id'], item['loi_title'])} src={item['cover']} />}>
              <Card.Meta title={item['loi_title']} description={
                <Space wrap>
                  {Array.from(item['tags'], (tag: any, j) => (
                    <Tag onClick={() => changeTag(tag['id'])} key={item['id'] + tag['id'] + i + j} color={tag['color']}>{tag['name']}</Tag>
                  ))}
                </Space>
              } />
            </Card>
          ))}
        </Flex>
        {/* <Button type="primary" onClick={() => nextPage()}>Next</Button> */}
        {/* {loading && <p>加载中...</p>} */}
        { loading && <Spin indicator={<LoadingOutlined style={{ fontSize: 24 }} spin />} /> }
        {/* {!loading && hasMore && <p>Scroll down to load more</p>} */}
        {/* {!hasMore && <p>No more data</p>} */}
        <Detail
          visible={dialogVisible}
          onCancel={handleCloseDialog}
          id={selectedId}
          title={selectedTitle}
        />
      </div>
    )
}

export default App;