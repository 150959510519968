import { useEffect, useState } from 'react';
import { Card, Flex, Modal } from 'antd';
import axios from 'axios';

const Detail = ({ visible, onCancel, title, id }: any) => {
    const [data, setData] = useState<any[]>([]);

    useEffect(() => {
        const fetchData = () => {
            console.log(id, visible);
            if (!id) {
                return;
            }
            // 在组件加载时发起数据请求
            axios.get(`https://api.moegoat.com/api/lois/${id}`)
                .then((res) => {
                    const newData = res.data.data;
                    console.log(newData);
                    setData(newData.images)
                })
                .catch((e) => {
                    console.log(e)
                })
        }

        fetchData();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);

    // 响应式展示图片
    return (
        <Modal
            open={visible}
            onCancel={onCancel}
            title={title}
            footer={null}
        >
            <Flex wrap="wrap" gap="small">
                {Array.from(data, (item: any, i) => (
                    <Card key={item}
                        hoverable
                        // style={{ width: 240 }}
                        cover={<img alt={item} src={item} />}>
                    </Card>
                ))}
            </Flex>
        </Modal>
    )
}

export default Detail;